import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid,
  Collapse,
  Divider,
  Chip
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from 'styled-components';
import { useState } from 'react';
import { iconFromIncidentStatus } from '../StatusIcon';
import { IComponent } from '../../interfaces';
import ReactMarkdown from 'react-markdown';
import { collapseLinebreaks } from '../../utils';

const CardTagsContainer = styled.div`
  margin: 20px 0px;
`;

const CardMessageContainer = styled.div`
  margin: 20px 0px;
`;

const CardStyled = styled(Card)<any>`
  box-shadow: ${({ boxshadow }) =>
    boxshadow
      ? '0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2) !important'
      : 'none !important'};
  border-radius: 0 !important;
`;

const CardContentStyled = styled(CardContent)<any>`
  background-color: #414141;
  ${({ padding }) => ('padding: ' + padding ? padding : '0px !important')};
  color: #ffffff;
`;

const IncidentUpdateContainer = styled.div`
  margin: 10px 0;
`;

const CardHeaderContainer = styled.div`
  margin: 10px 0;
`;

const CardFooterContainer = styled.div`
  margin-top: 20px;
  color: #ffffffb3;
`;

interface IIncidentUpdateRow {
  message: string;
  date: string;
  status: number;
}

// show three lines
function truncatable(message: string): boolean {
  return collapseLinebreaks(message).split('\n\n').length > 3;
}

function truncate(message: string) {
  let collMessage = collapseLinebreaks(message);
  if (!truncatable(collMessage)) return collMessage;
  return collMessage.split('\n\n', 3).join('\n\n') + ' ...';
}

const IncidentUpdateMessage = ({ message }: { message: string }) => {
  const [expand, setExpand] = useState(false);

  return (
    <IncidentUpdateContainer>
      <ReactMarkdown>
        {expand ? collapseLinebreaks(message) : truncate(message)}
      </ReactMarkdown>
      {truncatable(message) ? (
        <Chip
          label={expand ? 'show less' : 'show more'}
          size="small"
          clickable
          onClick={() => {
            setExpand(!expand);
          }}
          sx={{
            fontSize: '9px',
            border: '#7295C2',
            color: '#ffffff',
            borderStyle: 'solid',
            borderWidth: '1px'
          }}
        />
      ) : (
        <></>
      )}
    </IncidentUpdateContainer>
  );
};

const IncidentUpdateRow = ({ message, date, status }: IIncidentUpdateRow) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={1}>
        {iconFromIncidentStatus(status)}
      </Grid>
      <Grid item xs>
        <IncidentUpdateMessage message={message}></IncidentUpdateMessage>
      </Grid>
      <Grid item xs={2} sx={{ textAlign: 'right', color: '#ffffff99' }}>
        {new Date(date).toLocaleString()}
      </Grid>
    </Grid>
  );
};

export interface IIncidentCard {
  padding?: string;
  boxshadow?: boolean;
  name: string;
  message: string;
  created_at: string;
  incident_updates: Array<{
    id: string;
    message: string;
    status: number;
    created_at: string;
  }>;
  components: Array<IComponent>;
  groups: Array<{ id: string; name: string }>;
  scheduledFrom?: string;
  scheduledTo?: string;
}

const IncidentCard = ({
  name,
  message,
  created_at,
  incident_updates,
  components,
  groups,
  padding,
  boxshadow,
  scheduledFrom,
  scheduledTo
}: IIncidentCard) => {
  const [expand, setExpand] = useState(false);

  return (
    <CardStyled boxshadow={boxshadow ? 1 : 0}>
      <CardContentStyled padding={padding}>
        <CardHeaderContainer>
          <Typography variant="h5" component="div">
            {name}
          </Typography>
        </CardHeaderContainer>
        <CardTagsContainer>
          <Grid container spacing={1}>
            {(groups ?? []).concat(components).map((c) => {
              return (
                <Grid key={c.id + Math.random()} item>
                  <Chip
                    label={c.name}
                    sx={{
                      maxWidth: '200px',
                      border: '#7295C2',
                      background: '#7295C280',
                      color: '#ffffff',
                      borderStyle: 'solid',
                      borderWidth: '1px'
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </CardTagsContainer>
        <CardMessageContainer>
          <ReactMarkdown>{collapseLinebreaks(message)}</ReactMarkdown>
        </CardMessageContainer>

        {scheduledFrom || scheduledTo ? (
          <CardFooterContainer>
            <Grid container>
              <Grid item xs></Grid>
              <Grid item>
                {scheduledFrom ? new Date(scheduledFrom).toLocaleString() : ''}
                {scheduledFrom && scheduledTo ? ' - ' : ''}
                {scheduledTo ? new Date(scheduledTo).toLocaleString() : ''}
              </Grid>
            </Grid>
          </CardFooterContainer>
        ) : (
          <>
            {created_at ? (
              <CardFooterContainer>
                <Grid container>
                  <Grid item xs></Grid>
                  <Grid item>
                    {created_at ? new Date(created_at).toLocaleString() : ''}
                  </Grid>
                </Grid>
              </CardFooterContainer>
            ) : (
              <></>
            )}
          </>
        )}

        <Collapse in={expand}>
          <Divider
            sx={{
              borderColor: '#ffffff55',
              marginTop: '5px'
            }}
          ></Divider>
          {incident_updates.map((iu) => {
            return (
              <IncidentUpdateRow
                message={iu.message}
                date={iu.created_at}
                status={iu.status}
                key={iu.id}
              ></IncidentUpdateRow>
            );
          })}
        </Collapse>
        {incident_updates?.length ? (
          <Grid container justifyContent="center">
            <IconButton
              sx={{ color: '#ffffff' }}
              onClick={() => setExpand(!expand)}
            >
              {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Grid>
        ) : (
          <></>
        )}
      </CardContentStyled>
    </CardStyled>
  );
};

export default IncidentCard;
